export const stockholm = [
	{ src: "./img/stockholm/stockholm-1.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-2.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-3.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-4.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-5.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-6.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-7.JPG", label: "stockholm", wide: true },
	{ src: "./img/stockholm/stockholm-8.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-9.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-12.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-13.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-17.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-15.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-16.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-20.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-18.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-21.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-22.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-23.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-19.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-24.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-25.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-26.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-27.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-28.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-29.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-30.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-31.JPG", label: "stockholm", wide: true },
	{ src: "./img/stockholm/stockholm-32.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-33.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-37.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-34.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-40.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-35.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-36.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-38.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-39.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-41.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-42.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-44.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-45.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-46.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-47.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-48.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-49.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-43.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-50.JPG", label: "stockholm", wide: true },
	{ src: "./img/stockholm/stockholm-51.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-52.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-53.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-55.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-56.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-57.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-58.JPG", label: "stockholm", wide: true },
	{ src: "./img/stockholm/stockholm-59.JPG", label: "stockholm" },
	{ src: "./img/stockholm/stockholm-60.JPG", label: "stockholm" }
];

export const amsterdam = [
	{ src: "./img/amsterdam/amsterdam-2.JPG", label: "amsterdam", wide: true },
	{ src: "./img/amsterdam/amsterdam-4.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-3.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-5.JPG", label: "amsterdam", wide: true },
	{ src: "./img/amsterdam/amsterdam-6.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-8.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-10.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-11.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-12.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-13.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-14.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-15.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-16.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-17.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-18.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-20.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-21.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-22.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-23.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-24.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-25.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-27.JPG", label: "amsterdam" },
	{ src: "./img/amsterdam/amsterdam-26.JPG", label: "amsterdam", wide: true }
];

export const leNord = [
	{ src: "./img/lenord/lenord-3.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-5.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-6.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-7.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-8.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-21.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-9.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-10.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-11.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-12.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-13.JPG", label: "Le Nord, France", wide: true },
	{ src: "./img/lenord/lenord-16.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-15.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-14.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-17.JPG", label: "Le Nord, France" },
	{ src: "./img/lenord/lenord-20.JPG", label: "Le Nord, France", wide: true }
];

export const leSud = [
	{ src: "./img/lesud/lesud-1.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-2.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-3.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-4.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-5.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-6.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-7.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-8.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-9.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-10.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-11.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-12.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-13.JPG", label: "Le Sud, France", wide: true },
	{ src: "./img/lesud/lesud-14.JPG", label: "Le Sud, France", wide: true },
	{ src: "./img/lesud/lesud-15.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-16.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-17.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-18.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-19.JPG", label: "Le Sud, France" },
	{ src: "./img/lesud/lesud-20.JPG", label: "Le Sud, France" }
];

export const gothenburg = [
	{ src: "./img/gothenburg/gothenburg-2.JPG", label: "Gothenburg", wide: true },
	{ src: "./img/gothenburg/gothenburg-4.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-3.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-6.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-5.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-8.JPG", label: "Gothenburg" },

	{ src: "./img/gothenburg/gothenburg-7.JPG", label: "Gothenburg" },

	{
		src: "./img/gothenburg/gothenburg-11.JPG",
		label: "Gothenburg",
		wide: true
	},
	{ src: "./img/gothenburg/gothenburg-12.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-13.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-16.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-17.JPG", label: "Gothenburg" },
	{
		src: "./img/gothenburg/gothenburg-22.JPG",
		label: "Gothenburg"
	},
	{ src: "./img/gothenburg/gothenburg-21.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-28.JPG", label: "Gothenburg" },

	{ src: "./img/gothenburg/gothenburg-23.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-20.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-1.JPG", label: "Gothenburg" },
	{ src: "./img/gothenburg/gothenburg-24.JPG", label: "Gothenburg" },

	{ src: "./img/gothenburg/gothenburg-26.JPG", label: "Gothenburg" },

	{
		src: "./img/gothenburg/gothenburg-27.JPG",
		label: "Gothenburg",
		wide: true
	}
];

export const london = [
	{ src: "./img/london/london-10.JPG", label: "London" },
	{ src: "./img/london/london-11.JPG", label: "London" },
	{ src: "./img/london/london-1.JPG", label: "London" },
	{ src: "./img/london/london-3.JPG", label: "London" },
	{ src: "./img/london/london-4.JPG", label: "London" },
	{ src: "./img/london/london-5.JPG", label: "London" },
	{ src: "./img/london/london-6.JPG", label: "London" },
	{ src: "./img/london/london-7.JPG", label: "London" },
	{ src: "./img/london/london-8.JPG", label: "London" },
	{ src: "./img/london/london-9.JPG", label: "London" },
	{ src: "./img/london/london-12.JPG", label: "London", wide: true }
];

export const paris = [
	{ src: "./img/paris/paris-1.JPG", label: "Paris", wide: true },
	{ src: "./img/paris/paris-2.JPG", label: "Paris" },
	{ src: "./img/paris/paris-4.JPG", label: "Paris" },
	{ src: "./img/paris/paris-6.JPG", label: "Paris" },
	{ src: "./img/paris/paris-15.JPG", label: "Paris" },
	{ src: "./img/paris/paris-7.JPG", label: "Paris", wide: true },
	{ src: "./img/paris/paris-8.JPG", label: "Paris" },
	{ src: "./img/paris/paris-9.JPG", label: "Paris" },
	{ src: "./img/paris/paris-10.JPG", label: "Paris" },
	{ src: "./img/paris/paris-11.JPG", label: "Paris" },
	{ src: "./img/paris/paris-12.JPG", label: "Paris" },
	{ src: "./img/paris/paris-13.JPG", label: "Paris" },
	{ src: "./img/paris/paris-14.JPG", label: "Paris", wide: true }
];

export const sopot = [
	{ src: "./img/sopot/sopot-2.JPG", label: "sopot" },
	{ src: "./img/sopot/sopot-3.JPG", label: "sopot" },
	{ src: "./img/sopot/sopot-4.JPG", label: "sopot" },
	{ src: "./img/sopot/sopot-5.JPG", label: "sopot" },
	{ src: "./img/sopot/sopot-6.JPG", label: "sopot" },
	{ src: "./img/sopot/sopot-1.JPG", label: "sopot" },
	{ src: "./img/sopot/sopot-7.JPG", label: "sopot", wide: true }
];

export const portraits = [
	{ src: "./img/portrait/portrait-1.JPG", label: "Portrait" },
	{ src: "./img/portrait/portrait-2.JPG", label: "Portrait" },
	{ src: "./img/portrait/portrait-3.JPG", label: "Portrait" },
	{ src: "./img/portrait/portrait-4.JPG", label: "Portrait" },
	{ src: "./img/portrait/portrait-5.JPG", label: "Portrait" },
	{ src: "./img/portrait/portrait-6.JPG", label: "Portrait" },
	{ src: "./img/portrait/portrait-7.JPG", label: "Portrait" },
	{ src: "./img/portrait/portrait-8.JPG", label: "Portrait" }
];

export default {
	fonts: {
		body: 'Styrene, Georgia, Cambria, "Times New Roman", Times, serif',
		heading:
			'Styrene, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif'
	},
	fontSizes: [12, 14, 16, 20, 24, 32, 48, 72, 102],
	fontWeights: {
		body: 400,
		heading: 700
	},
	lineHeights: {
		body: 1.7,
		lead: 1.4,
		heading: 1.125
	},
	colors: {
		text: "#000",
		background: "#fff"
	},

	space: [0, 4, 8, 16, 32, 64, 128, 256],
	breakpoints: ["32em", "66em", "72em"],
	styles: {
		Layout: {
			color: "text",
			backgroundColor: "background",
			fontFamily: "body",
			lineHeight: "body"
		},
		Container: {
			maxWidth: 1160,
			padding: 3
		}
	}
};

/** @jsx jsx */
import { jsx, Grid } from "theme-ui";
import styles from "./index.module.scss";
import * as React from "react";
import { SimpleImg } from "react-simple-img";

export default class Gallery extends React.Component {
	render() {
		return (
			<Grid
				ref={this.props.ref}
				gap={[3, 4, 6]}
				columns={["1fr 1fr"]}
				sx={{ gridAutoFlow: "dense" }}
			>
				{this.props.images.map(photo => (
					<div
						sx={{
							gridColumn: photo.wide ? "span 2" : "span 1"
						}}
						key={photo.src}
					>
						<div className={styles.galleryImage}>
							<SimpleImg
								placeholder={false}
								src={photo.src}
								animationDuration={1}
								alt={photo.label}
							></SimpleImg>
						</div>
					</div>
				))}
			</Grid>
		);
	}
}

import { jsx } from "theme-ui";
import React from "react";
import { useRef, useEffect, useState } from "react";
import styles from "./index.module.scss";

const Slider = ({ slides }) => {
	const [curr, setCurr] = useState(0);
	const { length } = slides;

	const useInterval = (callback, delay) => {
		const savedCallback = useRef(() => {});
		useEffect(() => {
			savedCallback.current = callback;
		});
		useEffect(() => {
			const tick = () => savedCallback.current(),
				id = setInterval(tick, delay);
			return () => clearInterval(id);
		}, [delay]);
	};

	const goToNext = () => {
		setCurr(curr === length - 1 ? 0 : curr + 1);
	};

	useInterval(goToNext, 4500);

	if (!Array.isArray(slides) || length <= 0) {
		return null;
	}

	return (
		<div className={styles.frontPageGallery}>
			{SliderItems.map((SliderItem, i) => {
				return (
					<div
						key={SliderItem.image}
						className={
							i === curr ? `${styles.slideItemActive}` : `${styles.slideItem} `
						}
					>
						<img
							className={styles.slideItemImg}
							alt={SliderItem.image}
							src={SliderItem.image}
						></img>
					</div>
				);
			})}
		</div>
	);
};

export const Slideshow = () => {
	return <Slider slides={SliderItems} />;
};

const SliderItems = [
	{
		image: "./img/amsterdam/amsterdam-4.JPG"
	},
	{
		image: "./img/amsterdam/amsterdam-3.JPG"
	},
	{
		image: "./img/lesud/lesud-19.JPG"
	},
	{
		image: "./img/lesud/lesud-14.JPG"
	}
];

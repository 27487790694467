/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { NavLink } from "react-router-dom";

export default class MenuItem extends React.Component {
	render() {
		return (
			<NavLink
				activeClassName="active"
				to={this.props.slug}
				exact
				sx={{
					fontFamily: "heading",
					fontWeight: "body",
					marginBottom: [1, 1, 2],
					fontSize: 2,
					color: "#888",
					display: "block",
					textDecoration: "none",
					transition: "0.3s ease color",
					"&.active": {
						color: "text"
					}
				}}
			>
				{this.props.title}
			</NavLink>
		);
	}
}

/** @jsx jsx */
import { jsx, ThemeProvider, Container } from "theme-ui";
import theme from "./theme";
import MenuItem from "./components/MenuItem";
import Gallery from "./components/Gallery";
import ScrollToTop from "./components/ScrollToTop";
import { motion, AnimatePresence } from "framer-motion";
import { Grid } from "theme-ui";
import { Switch, Route, useLocation } from "react-router-dom";
import "./App.scss";
import { Slideshow } from "./components/Slideshow";

import {
	stockholm,
	gothenburg,
	amsterdam,
	leNord,
	leSud,
	london,
	paris,
	portraits,
	sopot
} from "./components/PhotoArrays";

const menuItems = [
	{ title: "Home", slug: "/" },
	{ title: "Stockholm", slug: "/stockholm" },
	{ title: "Gothenburg", slug: "/gothenburg" },
	{ title: "London", slug: "/london" },
	{ title: "Amsterdam", slug: "/amsterdam" },
	{ title: "Paris", slug: "/paris" },
	{ title: "Sopot", slug: "/sopot" },
	{ title: "Le Nord, France", slug: "/lenord" },
	{ title: "Le Sud, France", slug: "/lesud" },
	{ title: "Portraits", slug: "/portraits" }
];

function App() {
	const location = useLocation();
	return (
		<ThemeProvider theme={theme}>
			<ScrollToTop />
			<Container
				sx={{
					marginTop: [4, 5, 7],
					marginBottom: [4, 5, 7],
					maxWidth: ["1440px"],
					width: "88%"
				}}
			>
				<Grid columns={["auto", "auto", "200px 1fr"]} gap={[4, 5, 6]}>
					<div className="sidebar">
						<img
							sx={{ marginBottom: [4, 4, "96px"], textAlign: "center" }}
							width="48"
							src="./img/logo.svg"
							alt="logo"
						></img>
						<div
							style={{ position: "sticky", top: "32px" }}
							sx={{
								display: "grid",
								columnGap: 4,
								gridTemplateColumns: ["auto", "1fr 1fr", "auto"]
							}}
						>
							{menuItems.map(item => (
								<MenuItem
									key={item.title}
									title={item.title}
									slug={item.slug}
								/>
							))}
						</div>
					</div>
					<div style={{ position: "relative" }}>
						<AnimatePresence exitBeforeEnter>
							<Switch location={location} key={location.pathname}>
								<Route path="/stockholm" component={Stockholm} />
								<Route path="/gothenburg" component={Gothenburg} />
								<Route path="/london" component={London} />
								<Route path="/amsterdam" component={Amsterdam} />
								<Route path="/lenord" component={LeNord} />
								<Route path="/lesud" component={LeSud} />
								<Route path="/paris" component={Paris} />
								<Route path="/portraits" component={Portraits} />
								<Route path="/sopot" component={Sopot} />
								<Route path="/" exact component={Home} />
							</Switch>
						</AnimatePresence>
					</div>
				</Grid>
			</Container>
		</ThemeProvider>
	);
}

const pageVariants = {
	initial: {
		opacity: 0,
		x: -20
	},
	in: {
		opacity: 1,
		x: 0
	},
	out: {
		opacity: 0,
		x: 20
	}
};

const pageTransition = {
	type: "tween",
	ease: "easeOut",
	duration: 0.7
};

const pageStyle = {
	position: "relative"
};

function Stockholm() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div sx={{ height: "124px" }}>
				<h2 sx={{ fontWeight: "body", textAlign: "center" }}>Stockholm</h2>
			</div>
			<Gallery images={stockholm}></Gallery>
		</motion.div>
	);
}

function Amsterdam() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div sx={{ height: "124px" }}>
				<h2 sx={{ fontWeight: "body", textAlign: "center" }}>Amsterdam</h2>
			</div>
			<Gallery images={amsterdam}></Gallery>
		</motion.div>
	);
}

function LeNord() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div sx={{ height: "124px" }}>
				<h2
					sx={{ fontWeight: "body", textAlign: "center", marginBottom: "8px" }}
				>
					Le Nord, France
				</h2>
				<p sx={{ textAlign: "center", marginBottom: "96px" }}>
					Étretat, Deauville & Honfleur.
				</p>
			</div>

			<Gallery images={leNord}></Gallery>
		</motion.div>
	);
}
function LeSud() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div sx={{ height: "124px" }}>
				<h2
					sx={{ fontWeight: "body", textAlign: "center", marginBottom: "8px" }}
				>
					Le Sud, France
				</h2>
				<p sx={{ textAlign: "center", marginBottom: "96px" }}>
					Aix-en-Provence, Marseille & Bordeaux.
				</p>
			</div>
			<Gallery images={leSud}></Gallery>
		</motion.div>
	);
}

function Gothenburg() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div sx={{ height: "124px" }}>
				<h2 sx={{ fontWeight: "body", textAlign: "center" }}>Gothenburg</h2>
			</div>
			<Gallery images={gothenburg}></Gallery>
		</motion.div>
	);
}

function London() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div sx={{ height: "124px" }}>
				<h2 sx={{ fontWeight: "body", textAlign: "center" }}>London</h2>
			</div>
			<Gallery images={london}></Gallery>
		</motion.div>
	);
}

function Paris() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div sx={{ height: "124px" }}>
				<h2 sx={{ fontWeight: "body", textAlign: "center" }}>Paris</h2>
			</div>
			<Gallery images={paris}></Gallery>
		</motion.div>
	);
}

function Sopot() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div sx={{ height: "124px" }}>
				<h2 sx={{ fontWeight: "body", textAlign: "center" }}>Sopot</h2>
			</div>
			<Gallery images={sopot}></Gallery>
		</motion.div>
	);
}

function Portraits() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div sx={{ height: "124px" }}>
				<h2
					sx={{ fontWeight: "body", textAlign: "center", marginBottom: "8px" }}
				>
					Portraits
				</h2>
				<p sx={{ textAlign: "center", marginBottom: "96px" }}>
					Lea Poudrel aka Chula, Actress - Paris 2019
				</p>
			</div>
			<Gallery images={portraits}></Gallery>
		</motion.div>
	);
}

function Home() {
	return (
		<motion.div
			style={pageStyle}
			initial="initial"
			animate="in"
			exit="out"
			variants={pageVariants}
			transition={pageTransition}
		>
			<div
				sx={{
					justifyItems: "start",
					display: "grid",
					gridGap: [2, 3, 4],
					gridTemplateColumns: ["auto", "auto", "1fr 1fr"]
				}}
			>
				<div>
					<div sx={{ height: "124px" }}>
						<p sx={{ marginBottom: "12px" }}>
							“When I pronounce the word Silence, <br />I destroy it”.
						</p>
						<p sx={{ fontSize: 1 }}>Wisława Szymborska</p>
					</div>
					<p
						sx={{
							lineHeight: "body",
							maxWidth: "380px",
							marginBottom: "12px",
							marginRight: "56px"
						}}
					>
						I capture moments in order to freeze time. My photos are quiet
						ghosts of my past life and travels. Enjoy the silent exploration of
						my personal ethereal gallery.
					</p>
					<p sx={{ fontSize: 1, marginBottom: "56px" }}>Clementine Vendange</p>
					<div
						sx={{
							marginBottom: "56px"
						}}
					>
						<a
							sx={{
								color: "#777",
								textDecoration: "none",
								marginRight: "8px",
								"&:hover": {
									color: "#000"
								}
							}}
							href="mailto:clementine.vendange@orange.fr"
							target="_blank"
						>
							Email
						</a>{" "}
						<a
							sx={{
								color: "#777",
								textDecoration: "none",
								"&:hover": {
									color: "#000"
								}
							}}
							href="https://www.instagram.com/clementinevdg"
							target="_blank"
						>
							Instagram
						</a>
					</div>
				</div>
				<Slideshow></Slideshow>
			</div>
		</motion.div>
	);
}

export default App;
